<template>
    <div class="pd-t-60">
        <div class="container">
            
            <!-- <iframe src="https://drive.google.com/file/d/12UPL94HIhUGLKlyQw1pbZn_eROYAj9ym/view" style="width:600px; height:500px;" frameborder="0"> -->
            <!-- </iframe> -->

            <!-- <iframe src="https://docs.google.com/viewer?url=https://res.gosmart.mn/static/roadpay/roadpayhelp.pdf&embedded=true" style="width:600px; height:500px;" frameborder="0">
            </iframe> -->

            <iframe src="https://res.gosmart.mn/static/roadpay/roadpayhelp.pdf" style="border: o; width: 100%; height: calc(100vh - 60px)"></iframe>
            

        </div>    
    </div>
</template>